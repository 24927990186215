var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"id":"data-tables-view","fluid":"","tag":"section"}},[_c('material-card',{attrs:{"icon":"mdi-clipboard-list","icon-small":"","color":"primary","title":"Logs"}},[_c('v-card-text',[_c('v-text-field',{staticClass:"ml-auto",staticStyle:{"max-width":"250px"},attrs:{"append-icon":"mdi-magnify","hide-details":"","label":"Search records","single-line":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-divider',{staticClass:"mt-3"}),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.logs,"options":_vm.options,"search":_vm.search,"server-items-length":_vm.totalLogs,"loading":_vm.loading,"footer-props":{
          showFirstLastPage: true,
          'items-per-page-options': [5, 10, 15],
        }},on:{"update:options":function($event){_vm.options=$event},"update:search":function($event){_vm.search=$event}},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
        var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm.actionType(item.action)))])]}},{key:"item.data",fn:function(ref){
        var item = ref.item;
return [_c('div',[(item.data && item.data.toLowerCase().includes('failed'))?_c('v-icon',{attrs:{"color":"error"}},[_vm._v(" mdi-close-circle ")]):_vm._e(),_vm._v(_vm._s(item.data)+" ")],1)]}},{key:"item.createdAt",fn:function(ref){
        var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm.dateFormat(item.createdAt)))])]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }